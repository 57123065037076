@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Inter&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#root {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
}

/* TRACK */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.23);
}

/* HANDLE */
::-webkit-scrollbar-thumb {
  background: white;
  height: 10%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.overlay--disable {
  pointer-events: none;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  transition: opacity 2s ease-in-out;
}

.loader--disappear {
  opacity: 0;
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.logo {
  font-family: "DM Serif Display", serif;
  color: white;
  font-size: 7.5rem;
  letter-spacing: 0.5rem;
  padding: 0;
  margin: 0;
  transform: translateY(-50%);
  position: absolute;
  top: 50vh;
  animation: fadeUp 2.4s ease-in-out;
  transition: all 2s ease-in-out;
  text-align: center;
}

.intro .intro_text {
  font-family: "DM Serif Display", "serif";
  font-size: 3rem;
  color: #cccccc;
  position: absolute;
  top: 25vh;
  transform: translateY(-0%);
  animation: fadeUp 2.4s ease-in-out;
  letter-spacing: 5px;
  transition: all 2s ease-in-out;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
  z-index: 2;
}

.intro--disappear .logo {
  top: 15px;
  transform: scale(0.2);
  transform-origin: center top;
  position: absolute;
}

.navbar {
  display: flex;  
  flex-direction: row;
  left: 19.8%;
  position: absolute;
  z-index: 1;
  width: 60%;
  top: 0;
  justify-content: space-between;
  margin-top: 25px;
}

.navbar-fade-in {
  animation: fadeIn 2s ease-in-out;
}

.navbar-group {
  display: flex;
  flex-direction: row;
  width: 38%;
  justify-content: space-around;
}

.navbar-group:first-child .navbar-link {
  letter-spacing: 2.5px;
}

.navbar-link {
  margin: 0px;
  font-family: "Montserrat", "sans-serif";
  color: white;
  font-weight: 200;
  letter-spacing: 3.5px;
  font-size: 0.75rem;
  scale: 1;
  transition: scale 0.25s;
  position: relative;
  user-select: none;
}

.navbar-link::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -2px; /* Adjust this based on your font size */
  width: 0;
  height: 1px; /* Adjust this to change the underline thickness */
  background-color: white; /* Color of the underline */
  transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
  transform: translateX(-1%);
}

.navbar-link:hover {
  cursor: pointer;
  scale: 1.1;
}

.navbar-link:hover::after {
  width: 90%; /* Adjust this percentage to control the underline length */
  left: 5%; /* Shift the underline to center it under the text */
}

.placeholder { 
  width: 10%;
  border: 1px solid black;
}

.disappear {
  opacity: 0;
  filter: blur(5px);
  transition: opacity 1s ease, filter 1s ease;
}

.spinner {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-right: -42px;
  margin-top: -42px;
  animation: fadeIn 2.4s ease-in-out 1.2s forwards;
  opacity: 0;
}

.spinner__image {
  width: 164px;
  height: 164px;
  background-size: contain;
  /* background-image: url("/images/wawa.svg"); */
  animation: rotate 10s linear infinite;
}

.intro--disappear .spinner {
  opacity: 1;
  animation: fadeOut 1s ease-in-out forwards;
}

.intro__scroll {
  opacity: 0;
  color: white;
  font-family: "Montserrat", "sans-serif";
  font-size: 1rem;
  letter-spacing: 0.05rem;
  position: absolute;
  top: 24vh;
  font-weight: 200;
  letter-spacing: 4px;
}

.intro__scroll_2 {
  opacity: 0;
  color: white;
  font-family: "Montserrat", "sans-serif";
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  position: absolute;
  top: 30vh;
  font-weight: 200;
  letter-spacing: 4px;
  max-width: 300px;
  text-align: center;
}

.intro--disappear .intro__scroll {
  animation: fadeIn 1s ease-in-out 1.5s forwards;
}

.overlay--scrolled .intro__scroll {
  animation: fadeOut 1s ease-in-out forwards;
}

.intro--disappear .intro__scroll_2 {
  animation: fadeIn 1s ease-in-out 1.5s forwards;
}

.overlay--scrolled .intro__scroll_2 {
  animation: fadeOut 1s ease-in-out forwards;
}

.explore {
  padding: 1rem 2rem;
  border: none;
  font-family: "DM Serif Display", serif;
  font-size: 1.25rem;
  letter-spacing: 0.25rem;
  color: #3535cc;
  border-radius: 32px;
  cursor: pointer;
  display: inline-block;
  margin-top: 320px;
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.42s ease-in-out;
  animation: fadeIn 2.4s ease-in-out 2s forwards;
  opacity: 0;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}

.modal-content {
  background-color: #fff;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.resume_container {
  position: fixed;
  height: 300vh;
  width: 100vw;
  background: #000000aa;
  display: flex; /* Add this */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  cursor: pointer;
}

.resume {
  width: 70vw;
  height: 80vh;
  align-items: center;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.explore:hover {
  color: white;
}

.explore:hover:before {
  width: 200%;
  height: 300%;
  border-radius: 100%;
}

.explore:before {
  content: "";
  background: #3535cc;
  position: absolute;
  width: 0%;
  bottom: 0;
  left: 50%;
  height: 0%;
  transition: 0.42s ease-in-out;
  z-index: -1;
  transform: translateX(-50%) translateY(50%);
}

.intro--disappear .explore {
  animation: fadeOut 1s ease-in-out forwards;
}

.outro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 4s ease-in-out;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.outro--appear {
  opacity: 1;
}

.gap {
  gap: 16px;
}

.justify-between {
  justify-content: space-between;
}

.border {
  border: 1px solid white;
}

.outro__text {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  color: white;
}

.outro__text_small {
  font-family: "Inter", sans-serif;
  font-weight: 200;
  letter-spacing: 2px;
  font-size: 1rem;
  color: white;
}

.restart_button {
  width: 40px;
  height: 40px;
  margin: 0px;
  position: relative;
  top: 20px;
}

.outro_button {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  transition: scale 0.2s ease; /* Smooth transition for font size changes */
  width: fit-content;
  user-select: none;
}

.line {
  border-bottom: 1px solid white;
  width: 400px;
  margin-bottom: 10px;
}

.social_icon {
  width: 30px;
  height: 30px;
}

.social_button {
  transition: scale 0.2s ease; /* Smooth transition for font size changes */
  width: fit-content;
  user-select: none;
}

.social_button:hover {
  scale: 1.05;
  cursor: pointer;
}

.social_button:active {
  scale: 0.95;
}

.outro_button:hover {
  scale: 1.05;
  cursor: pointer; /* Change cursor to pointer on hover */
}

/* RESPONSIVE */
@media screen and (max-width: 1100px) {
  .navbar {
    display: flex;  
    flex-direction: row;
    left: 4.8%;
    position: absolute;
    z-index: 1;
    width: 90%;
    top: 0;
    justify-content: space-between;
    margin-top: 25px;
  }
  
  .navbar-group {
    display: flex;
    flex-direction: row;
    width: 38%;
    justify-content: space-around;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    font-size: 3rem;
    letter-spacing: 0.25rem;
  }

  .line {
    width: 280px;
  }

  .intro--disappear .logo {
    top: 12px;
    transform: scale(0.35);
    transform-origin: center top;
  }

  .intro .intro_text {
    font-size: 2rem;
    top: 35vh;
  }

  .spinner__image {
    width: 82px;
    height: 82px;
  }

  .outro__text {
    font-size: 1rem;
  }

  .navbar {
    margin-top: 19px;
  }

  .navbar-link {
    font-size: 0.55rem;
  }
}

@media screen and (max-width: 540px) {
  .navbar {
    display: none;
  }

  .intro .intro_text {
    font-size: 1.5rem;
    top: 35vh;
  }

  .logo {
    font-size: 2rem;
    letter-spacing: 0.25rem;
  }

  .intro__scroll {
    font-size: 0.8rem;
  }

  .intro__scroll_2 {
    font-size: 0.7rem;
  }
}
/* ANIMATIONS */

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
    filter: blur(9px);
  }

  100% {
    opacity: 1;
    filter: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes onHover {
  0% {
    scale: 1.0;
  }
  100% {
    scale: 1.05;
  }
}